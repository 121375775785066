.checkoutProduct{

    display:flex;
    margin-top:20px;
    margin-bottom: 20px;
    margin-left: 60px;
   
}

.checkoutProduct__info{
    padding-left: 50px;
}

.checkoutProduct__info > button{

    background:#f0c14b;
    border-radius: 2px;
    border:1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;  
    color:black;
    max-width: 150px;
}

.checkoutProduct__image{
    object-fit: contain;
    width:180px;
    height:180px;
}

.checkoutProduct__title{
    font-size: 17px;
    font-weight:bold;
}
.checkoutProduct__rating{
    display: flex;
}