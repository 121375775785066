.checkout{
    display:flex;
    padding:20px;
    background-color: white;
    height:max-content;
}


.checkout__ad{
    width:100%;
    margin-bottom: 10px;
    
}
.checkout__title{
    font-weight: bold;
    padding:10px;
    margin-right: 10px;
    border-bottom: 1px solid lightgrey;
}
