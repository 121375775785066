.payment{
    background-color: white;
}
.payment__container > h1{
    text-align: center;
    padding:10px;
    font-weight: 40px;
    background-color: rgb(234,237,237);
    border-bottom: 1px solid lightgray;
}

.payment__container > h1 > a{
    text-decoration: none;
}

.payment__section{
    display:flex;
    padding:20px;
    margin:0 20px;
    border-bottom: 1px solid lightgray;
}
.payment__title{
    flex:0.2;
}
.payment__address, .payment__items{
    flex:0.8;
}