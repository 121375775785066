*{
    font-family: sans-serif;
}
.header{
    height:65px;
    display:flex;
    align-items: center;
    background-color: #131921;
    position:sticky;
    top:0;
    z-index: 100;
}
.header_logo{
    width:100px;
    height:40px;
    object-fit: contain;
    margin:0px 20px;
    margin-top: 12px;

}
.header_search{
    display:flex;
    flex:1;
    align-items: center;
    border-radius: 24px;
}
.header_searchInput{

    height:40px;
    padding:10px;
    border:1px solid black;
    border-radius:3px;
    width:90%;

}
#search_logo{
    font-size: 22.5px;
    
    
    border:1px solid orange;
    padding: 7px;
    background-color: orange;
}
.header_nav{
    display: flex;
    justify-content: space-evenly;
}
.header_option{
    display:flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color:white;
}
.header_optionLineOne{
    font-size: 10px;
}
.header_optionLineTwo{
    font-size: 13px;
    font-weight: 800;
}
.header_optionBasket{
    display: flex;
    align-items: center;
}
#header_Basket{
    font-size: 30px;
    color:white;
}
.header_basketCount{
    font-size:15px ;
    margin-left: 10px;
   margin-right: 10px;
}